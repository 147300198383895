import { styled } from "styled-components";

import { Button, Colored, desktop, Divider, Text } from "@vericus/cadmus-ui";

import finalImg from "@/assets/illustrations/student-running-and-checkmark.png";
import { useCountdown } from "@/features/timeline";
import { formatDateFull } from "@/utils/datetime";

import { ActionContainer, Illustration } from "./styles";

interface ExamProps {
  assessmentName: string;
  userName: string;
  submittedAt: string;
  userEmail?: string;
  isLDBExam?: boolean;
  /** Valid future writing date, if there is still exam time left. */
  writingDate?: string;
  /** Optional callback to continue editing if available */
  onContinueEditing?: () => void;
}

/**
 * Confirmation message for a successful exam final submission.
 */
export function ExamSubmissionConfirmation(props: ExamProps) {
  const { isLDBExam, writingDate, onContinueEditing } = props;
  const onExitLDB = () => window.open("/?rldbxb=1");

  return (
    <Container>
      <Text kind="displayTwo">Your exam has been submitted</Text>
      <GridDivider />
      <InfoContainer>
        <Heading>
          <Text kind="headingFour">{props.assessmentName}</Text>
          <Text kind="label">Submitted by {props.userName}</Text>
        </Heading>

        <div>
          <Text kind="bodyMd">
            On {formatDateFull(new Date(props.submittedAt))}
          </Text>
          {props.userEmail && (
            <Text kind="bodyMd">
              A confirmation of your submission has been sent to:{" "}
              <Colored color="blue500">{props.userEmail}</Colored>
            </Text>
          )}
        </div>
        {(writingDate || isLDBExam) && (
          <ActionContainer>
            {writingDate && <TimeRemainingMessage endDate={writingDate} />}
            <ButtonContainer>
              {writingDate && onContinueEditing && (
                <Button onClick={onContinueEditing} iconName="Edit" kind="dark">
                  Continue Working
                </Button>
              )}
              {isLDBExam && <Button onClick={onExitLDB}>Close Browser</Button>}
            </ButtonContainer>
          </ActionContainer>
        )}
      </InfoContainer>
      <GridIllustration src={finalImg} alt="" />
    </Container>
  );
}

// Stateful countdown towards the time remaining to continue editing
function TimeRemainingMessage(props: { endDate: string }) {
  const { message } = useCountdown(props.endDate);
  return (
    <Text kind="bodySm" role="timer" aria-live="polite" aria-atomic="true">
      You have {message} of writing time remaining
    </Text>
  );
}

const ButtonContainer = styled.div`
  display: inline-flex;
  gap: 20px;
`;

const Container = styled.div`
  display: grid;
  gap: 16px;
  grid-template:
    "heading" auto
    "divider" auto
    "info" 1fr
    / 1fr;

  ${desktop`
    grid-template:
    "heading illustration" auto
    "divider illustration" auto
    "info illustration" 1fr
    / 1fr auto;
  `}
`;

const Heading = styled.div`
  grid-area: heading;
`;

const GridDivider = styled(Divider)`
  grid-area: divider;
`;

const InfoContainer = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const GridIllustration = styled(Illustration)`
  height: auto;
  width: 100%;

  grid-area: none;
  display: none;

  ${desktop`
    grid-area: illustration;
    display: block;
  `}
`;
