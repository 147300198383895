import { Fragment, useCallback, useMemo } from "react";
import { styled } from "styled-components";

import { Callout, Radio, Text } from "@vericus/cadmus-ui";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { selectAnswerSnapshot, setLocalSnapshot } from "@/features/authority";
import { Snapshot } from "@/stores/snapshot";

import { shuffleWithSeed } from "../../utils/shuffle-with-seed";
import * as styles from "./answer-form.css";

interface Props {
  /** Field identifier */
  identifier: string;
  /** Answer Block ID */
  answerBlockId: string;
  /** Choices for the multiple choice question. */
  choices: Array<{ content: string; identifier: string }>;
  /** Make the choices readonly */
  isReadonly?: boolean;
  /** Whether shuffle options */
  shuffle?: boolean;
  /** Maximum number of choices allowed */
  maxChoices: number;
}

/**
 * Answer response form section to respond to a multiple choice question.
 */
export function MultiResponseForm(props: Props) {
  const {
    identifier,
    answerBlockId,
    isReadonly = false,
    choices,
    shuffle,
    maxChoices,
  } = props;

  const dispatch = useAppDispatch();
  const snapshot = useAppSelector((state) =>
    selectAnswerSnapshot(state, answerBlockId)
  );
  const version = snapshot?.version ?? 0;

  const answerChoiceIds = useMemo(
    () => getAnswerChoiceIds(snapshot, identifier),
    [snapshot, identifier]
  );

  const choicesToDisplay = useMemo(() => {
    if (!shuffle) return choices;
    return shuffleWithSeed(choices, answerBlockId);
  }, [shuffle, choices, answerBlockId]);

  // Callback to set choice in the local snapshot
  const setChoice = useCallback(
    (choiceId: string) => {
      const newVersion = version + 1;
      let modifiedAnswerChoiceIds = [];

      if (maxChoices > 1) {
        modifiedAnswerChoiceIds = [...answerChoiceIds];
        if (!modifiedAnswerChoiceIds.includes(choiceId)) {
          modifiedAnswerChoiceIds.push(choiceId);
        } else {
          modifiedAnswerChoiceIds = modifiedAnswerChoiceIds.filter(
            (id) => id !== choiceId
          );
        }
      } else {
        modifiedAnswerChoiceIds = [choiceId];
      }

      const snapshot: Snapshot = {
        version: newVersion,
        answerChoiceIds: modifiedAnswerChoiceIds,
        answerFields: {
          [identifier]: modifiedAnswerChoiceIds,
        },
      };
      dispatch(
        setLocalSnapshot({
          answerBlockId,
          snapshot,
        })
      );
    },
    [identifier, dispatch, version, answerBlockId, answerChoiceIds, maxChoices]
  );

  return (
    <div className={styles.optionResponseForm}>
      {choicesToDisplay.map((choice, index) => (
        <Fragment key={`${choice.identifier}-${index}`}>
          <div className={styles.optionContainer}>
            <div
              className={
                styles.option[
                  answerChoiceIds.includes(choice.identifier)
                    ? "selected"
                    : "default"
                ]
              }
            >
              <StyledRadioButton
                name={choice.identifier}
                value={choice.identifier}
                checked={answerChoiceIds.includes(choice.identifier)}
                colorVariant="primary"
                contentEditable={false}
                onClick={() => setChoice(choice.identifier)}
                disabled={isReadonly}
                content={choice.content}
              />
            </div>
          </div>
        </Fragment>
      ))}
      {maxChoices > 1 && (
        <Callout variant="info">
          <Text kind="bodyMd">This question has multiple correct answers</Text>
        </Callout>
      )}
    </div>
  );
}

function getAnswerChoiceIds(
  snapshot: Snapshot | undefined,
  identifier: string
): string[] {
  if (snapshot?.answerFields) {
    return snapshot.answerFields[identifier] ?? [];
  }
  return snapshot?.answerChoiceIds ?? [];
}

// Needed so the label takes up whole parent `div` area and becomes more accessible
const StyledRadioButton = styled(Radio)`
  width: 100%;
  margin: 0;
  padding: 12px 16px;
`;
