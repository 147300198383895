import { Editor } from "@vericus/cadmus-editor-prosemirror";

import { makeVar, useReactiveVar } from "@apollo/client";

import { ClassicEditorName } from "./types";

/** State for active answer Editor. */
export interface ActiveEditorInfo {
  /** Editor ID */
  id: string;
  /** Editor instance corresponding to the active Answer Block. */
  editor: Editor;
  /** Optional classic Editor identifier */
  editorName: ClassicEditorName | null;
}

/** Reactive variable storing the current active editor, if any. */
export const activeEditorVar = makeVar<ActiveEditorInfo | null>(null);

/** Hook to get the current focused editor and control it. */
export function useActiveEditor(): Editor | null {
  const activeEditor = useReactiveVar(activeEditorVar);
  const editor = activeEditor?.editor ?? null;
  return editor;
}

/** Hook to get the currently focused editor and metadata. */
export function useActiveEditorInfo(): ActiveEditorInfo | null {
  return useReactiveVar(activeEditorVar);
}

/** Set an editor to be the active prompt editor. */
export function setActiveEditor(
  id: string,
  editor: Editor,
  editorName: ClassicEditorName | null
) {
  const activeEditor = activeEditorVar();
  if (activeEditor?.id === id) return;
  activeEditorVar({ id, editor, editorName });
}

/** Clear current active prompt editor */
export function clearActiveEditor() {
  if (!activeEditorVar) return;
  activeEditorVar(null);
}
