import { isAnyOf } from "@reduxjs/toolkit";

import { __GLOBAL_BROWSER_STORAGE, __GLOBAL_TENANT } from "@/client/globals";
import { AppStartListening } from "@/data/listenerMiddleware";
import {
  goNextPage,
  goPrevPage,
  goToPage,
  PageInfo,
  selectCurrentPage,
} from "@/features/answer";
import {
  selectAnswerSnapshot,
  setLocalSnapshot,
  setPendingSnapshot,
} from "@/features/authority";
import { QuestionType } from "@/generated/graphql";
import { clearActiveEditor } from "@/stores/editor-store";
import { setItem } from "@/utils/localStorage";

/**
 * Start listeners firing effects related to the Answer slice.
 */
export function startAnswerListeners(startListening: AppStartListening) {
  // Set of answer blocks that have changed, passed as a mutable dependency to
  // the listener
  const CHANGED_ANSWER_BLOCKS: Set<string> = new Set();

  startPageNavigationListener(startListening);
  startLocalSnapshotListener(startListening, CHANGED_ANSWER_BLOCKS);
}

/**
 * Start listener for answer page navigation events. Following effects are
 * fired:
 *
 *   1. The `state.answer.currentPageIndex` is saved to localStorage.
 *
 *   2. The current active editor is cleared when the question type is not an
 *      extended answer.
 */
export function startPageNavigationListener(startListening: AppStartListening) {
  // Listen to the change of answer page, clear active editor when question type is
  // not extended type.
  startListening({
    matcher: isAnyOf(goPrevPage, goNextPage, goToPage),
    effect: (_action, listenerApi) => {
      const state = listenerApi.getState();

      // Save currentPageIndex to localStorage
      if (state.authority.workId) {
        setItem(
          state.authority.workId,
          "currentPageIndex",
          JSON.stringify(state.answer.currentPageIndex)
        );
      }

      // Clear active editor if the current answer is not an extended answer
      const currentPage: PageInfo = selectCurrentPage(state);
      if (currentPage.page === "answer") {
        if (currentPage.questionType !== QuestionType.Extended) {
          clearActiveEditor();
        }
      }
    },
  });
}

/**
 * Track Answer Blocks that have changed and queue their latest snapshot for
 * saving after a debounce interval.
 *
 * Listen to `setLocalSnapshot` actions which set the local snapshot states on
 * every change. It will globally keep track of the set of Answer Block IDs that
 * have changed. The effect will "debounce" and eventually push the latest
 * snapshot for each changed Answer Block to the pending snapshots queue.
 *
 * @param startListening Redux listener middleware's start function
 * @param changedBlockIds Mutable set to track which answer blocks have changed over
 *   the course of the listener's lifetime.
 */
export function startLocalSnapshotListener(
  startListening: AppStartListening,
  changedBlockIds: Set<string>,
  delayMs = 200
) {
  startListening({
    actionCreator: setLocalSnapshot,
    effect: async (action, listenerApi) => {
      changedBlockIds.add(action.payload.answerBlockId);

      // Cancel any in-progress instances of this listener
      listenerApi.cancelActiveListeners();
      // Delay before starting actual work
      await listenerApi.delay(delayMs);

      const state = listenerApi.getState();
      for (const answerBlockId of changedBlockIds.keys()) {
        const snapshot = selectAnswerSnapshot(state, answerBlockId);
        if (snapshot) {
          listenerApi.dispatch(
            setPendingSnapshot({
              answerBlockId,
              snapshot,
            })
          );
        }
      }

      changedBlockIds.clear();
    },
  });
}
