import {
  CadmusEditorContent,
  useCadmusEditor,
} from "@vericus/cadmus-editor-prosemirror";
import { useManualPreset } from "@vericus/cadmus-manual";

import * as Sentry from "@sentry/react";

import { useAppSelector } from "@/data/hooks";
import { selectAnswerSnapshot } from "@/features/authority";
import {
  ClassicEditorName,
  useClassicAnswerBlockId,
  useNotesEditor,
} from "@/stores/editor-store";

export function NotesEditorContent(props: { hideManual?: boolean }) {
  const editor = useNotesEditor();
  const openManualPreset = useManualPreset();
  return (
    <Sentry.ErrorBoundary>
      <CadmusEditorContent
        editor={editor}
        onOpenManualPreset={props.hideManual ? undefined : openManualPreset}
      />
    </Sentry.ErrorBoundary>
  );
}

export function NotesEditorContentReadOnly() {
  const answerBlockId = useClassicAnswerBlockId(ClassicEditorName.Notes);
  const snapshot = useAppSelector((state) =>
    answerBlockId ? selectAnswerSnapshot(state, answerBlockId) : null
  );
  const content = snapshot?.answerDoc ?? null;
  const editor = useCadmusEditor(
    {
      editorId: "notes-readonly",
      editorA11yLabel: "Assessment notes (read-only)",
      content,
      editable: false,
    },
    [content]
  );
  return (
    <CadmusEditorContent editor={editor} aria-label="Assessment notes editor" />
  );
}
