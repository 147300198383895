import { createAction, isRejected, TaskAbortError } from "@reduxjs/toolkit";

import { __GLOBAL_BROWSER_STORAGE } from "@/client/globals";
import { AppStartListening } from "@/data/listenerMiddleware";
import { onFocus } from "@/data/setup-dom-listeners";
import { AppDispatch, RootState } from "@/data/store";
import {
  PendingSnapshot,
  saveBlockSnapshot,
  saveFull,
  SavingMechanism,
  selectSaveNetworkError,
  selectSavingMechanism,
  setPendingSnapshot,
} from "@/features/authority";
import { ClassicEditorName } from "@/stores/editor-store";

/** Start Snapshot draing queue. */
export const snapshotQueueStarted = createAction(
  "authority/startSnapshotQueue"
);

/** Stop Snapshot draining queue. */
export const snapshotQueueStopped = createAction("authority/stopSnapshotQueue");

/** Flush the queue immediately. */
export const snapshotQueueFlush = createAction("authority/flushSnapshotQueue");

/**
 * Start listening to pending snapshots queue and drain it using the
 * `saveBlockSnapshot` or `saveFull` action for BLOCK and FULL saving
 * mechanisms respectively.
 *
 * In case of network error, the queue will try to save the snapshot again
 * after delay. Failed snapshots will be stored in the browser storage.
 *
 * The draining is done in a forked task which is started with the
 * `snapshotQueueStarted` action and stops with the `snapshotQueueStopped`
 * action.
 *
 * The forked draining task will wait for the following actions to trigger a
 * synchronous flush:
 *
 *   1. `snapshotQueueFlush` action
 *   2. `setPendingSnapshot` action
 *
 */
export function startSnapshotQueueListener(startListening: AppStartListening) {
  startListening({
    actionCreator: snapshotQueueStarted,
    effect: async (_action, listenerApi) => {
      // Only allow one instance of this listener to run at a time
      listenerApi.unsubscribe();

      // Start a child job that will infinitely loop receiving messages
      const pollingTask = listenerApi.fork(async (forkApi) => {
        try {
          for (;;) {
            // Only pause if there are no snapshots
            if (!selectHasPendingSnapshots(listenerApi.getState())) {
              // Wait for pendingSnapshots queue to fill
              await forkApi.pause(
                listenerApi.condition((action) => {
                  if (snapshotQueueFlush.match(action)) return true;
                  if (setPendingSnapshot.match(action)) return true;
                  if (onFocus.match(action)) return true;
                  return false;
                })
              );
            }

            const state = listenerApi.getState();
            const savingMechanism = selectSavingMechanism(
              listenerApi.getState()
            );

            // Dispatch all pending snapshots synchronously
            const pendingSnapshots = state.authority.pendingSnapshots;
            for (const pending of pendingSnapshots) {
              const saveResult = await saveSnapshot(
                pending,
                savingMechanism,
                listenerApi.dispatch
              );

              // If the save failed due to network error, wait a bit and retry
              if (
                isRejected(saveResult) &&
                selectSaveNetworkError(listenerApi.getState())
              ) {
                storeSnapshotLocally(pending);

                await listenerApi.delay(3000);
                break;
              }
            }
          }
        } catch (err) {
          if (err instanceof TaskAbortError) {
            // could do something here to track that the task was cancelled
          }
        }
      });

      // Wait for the "stop polling" action
      await listenerApi.condition(snapshotQueueStopped.match);
      pollingTask.cancel();
    },
  });
}

/**
 * Save a pending snapshot either using the new BlockSnapshot mutation or the
 * old Full Save mutation for compatibility.
 *
 * @param pending - The pending snapshot to save
 * @param savingMechanism - BLOCK or FULL
 * @param dispatch - The dispatch function
 * @returns The result of the save action.
 */
async function saveSnapshot(
  pending: PendingSnapshot,
  savingMechanism: SavingMechanism,
  dispatch: AppDispatch
) {
  if (shouldSaveBlockSnapshot(savingMechanism, pending)) {
    return dispatch(saveBlockSnapshot(pending));
  } else {
    return dispatch(
      saveFull({
        pendingSnapshot: pending,
      })
    );
  }
}

function isClassicEditor(answerBlockId: string) {
  return (
    answerBlockId === ClassicEditorName.Body ||
    answerBlockId === ClassicEditorName.Notes ||
    answerBlockId === ClassicEditorName.References
  );
}

// Determines if SaveBlockSnapshot mutation should be called.
//
// SaveBlockSnapshot Mutation is used for MFA (which has BLOCK saving mechanism),
// but in MFA there is still Notes Editor(Classic one) that needs to be saved
// with SaveFull mutation.
function shouldSaveBlockSnapshot(
  savingMechanism: SavingMechanism,
  pending: PendingSnapshot
) {
  return (
    savingMechanism === SavingMechanism.BLOCK &&
    !isClassicEditor(pending.answerBlockId)
  );
}

function storeSnapshotLocally(pending: PendingSnapshot) {
  const storage = __GLOBAL_BROWSER_STORAGE.current;
  if (storage) {
    storage.storeSnapshot(pending);
  }
}

function selectHasPendingSnapshots(state: RootState): boolean {
  return state.authority.pendingSnapshots.length > 0;
}
