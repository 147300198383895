import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AssessmentType, ExamTiming, TaskFormat } from "@/generated/graphql";

import {
  getExamReadingTime,
  getExamWritingTime,
  getTimeLimit,
  updateSheet,
} from "./assignment-actions";

export interface AssignmentState {
  /**
   * The loaded ID of the assessment.
   */
  assessmentId?: string;

  /**
   * The type of assessment. (Assignment or Exam)
   */
  type: AssessmentType;

  /**
   * Assessment format is either classic or new multi-format.
   */
  format: TaskFormat;

  /**
   * Whether the student can resubmit after the due date.
   */
  allowLateResubmission: boolean;

  /**
   * The time limit for the assignment (in minutes).
   */
  timeLimit: number | null;

  /**
   * Whether the time limit has been extended.
   */
  timeLimitExtended: boolean;

  /**
   * The referencing style for the assignment.
   */
  referencingStyle: string | null;

  /**
   * Exam Setting
   *
   * If `autoSubmission` is not enabled, this is the time limit for late
   * submissions.
   * If `autoSubmission` is enabled and is set to null, the user can submit
   * late indefinitely.
   */
  lateSubmissionTimeLimit: number | null;

  /**
   * Exam Setting
   * Whether the work will be auto submitted at the end of the exam.
   */
  autoSubmission: boolean;

  /**
   * Exam Setting
   * The timing of the exam. Window or Live.
   */
  examTiming: ExamTiming;

  /**
   * Exam Setting
   * Whether disable backtrack navigation in Multi-Format exams.
   */
  examBacktrackDisabled: boolean | null;

  /**
   * Exam Setting
   * The reading time portion of the exam.
   * This is optional. If not set, the exam is considered to have
   * not been given reading time.
   */
  readingTime: number | null;

  /**
   * Exam Setting
   * The writing time portion of the exam.
   */
  writingTime: number | null;

  /**
   * Exam Setting
   * Exam is considered closed, where the current time is past the `examEndDate`.
   * No more submissions are accepted.
   */
  examIsOver: boolean;

  /**
   * Exam Setting
   * The student/enrollment been marked as deferred
   * exams. */
  examDeferred: boolean;

  /**
   * Exam Setting
   * Exam runs inside a lockdown browser.
   */
  isLockdownBrowser: boolean;

  /**
   * Anonymous marking setting for the assessment.
   */
  anonymousMarking: boolean;
}

export const initialState: AssignmentState = {
  type: AssessmentType.Assignment,
  format: TaskFormat.Classic,
  examTiming: ExamTiming.Live,
  examBacktrackDisabled: null,
  autoSubmission: false,
  allowLateResubmission: false,
  readingTime: null,
  writingTime: null,
  timeLimit: null,
  timeLimitExtended: false,
  lateSubmissionTimeLimit: null,
  referencingStyle: null,
  examIsOver: false,
  examDeferred: false,
  isLockdownBrowser: false,
  anonymousMarking: false,
};

export const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    markExamAsOver: (state) => {
      state.examIsOver = true;
    },
    onContinueExam: (state) => {
      state.examIsOver = false;
    },
    setTaskFormat: (state, action: PayloadAction<TaskFormat>) => {
      state.format = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSheet, (state, action) => {
      state.assessmentId = action.payload.assessmentId;
      const settings = action.payload.settings;
      const reqs = action.payload.requirements;

      if (reqs) {
        const isExam = reqs.assessmentType === AssessmentType.Exam;

        const [readingTime] = getExamReadingTime(action.payload);
        const [writingTime] = getExamWritingTime(action.payload);
        const [timeLimit, timeLimitExtended] = getTimeLimit(action.payload);

        state.type = reqs.assessmentType;
        state.examTiming = reqs.examTiming;
        state.readingTime = readingTime;
        state.writingTime = writingTime;
        state.timeLimit = timeLimit;
        state.timeLimitExtended = timeLimitExtended;
        state.referencingStyle = reqs.referencingStyle;
        state.allowLateResubmission = reqs.allowLateResubmission;
        state.anonymousMarking = reqs.anonymousMarking ?? false;

        if (isExam) {
          state.lateSubmissionTimeLimit = reqs.lateSubmissionTimeLimit;
          state.autoSubmission = reqs.enableExamAutoSubmission;
          state.examDeferred = settings?.examDeferred ?? false;
          state.isLockdownBrowser = reqs.enableExamLdb ?? false;
          state.examBacktrackDisabled = reqs.disableExamBacktrack;
        }
      }

      const workTimeLimit = settings?.timeLimit ?? null;
      if (
        state.timeLimit !== null &&
        workTimeLimit !== null &&
        workTimeLimit > state.timeLimit
      ) {
        state.timeLimit = workTimeLimit;
        state.timeLimitExtended = true;
      }
    });
  },
});

export const { markExamAsOver, onContinueExam, setTaskFormat } =
  assignmentSlice.actions;

export const assignmentReducer = assignmentSlice.reducer;
