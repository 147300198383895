import { Anchored, Button, Spacer, Text } from "@vericus/cadmus-ui";

import illustration from "@/assets/illustrations/students-discussing.png";

import { Actions, FlexImg, FlexText, Padding, XPFlex } from "./styles";

interface Props {
  /** Survey redirect URL */
  href: string;
  /** Callback to dismiss interactions */
  onDismiss: () => void;
}

/**
 * Link to further survey. Students are redirected to a new tab.
 */
export const Survey = (props: Props) => (
  <Padding>
    <XPFlex>
      <FlexText>
        <Text kind="headingThree">
          How was your experience using Cadmus in this assignment?
        </Text>
        <Text kind="bodySm">
          Thanks for the feedback! If you have a spare couple of minutes,{" "}
          {"we'd "}
          love to ask some follow up questions. Your answers help determine what
          features {"we'll"} build in the future.
        </Text>
        <Spacer spacing={27} />
        <Actions>
          <Anchored href={props.href} newTab>
            <Button kind="dark" onClick={props.onDismiss}>
              Take 2 min survey
            </Button>
          </Anchored>
          <Button onClick={props.onDismiss}>No thanks</Button>
        </Actions>
      </FlexText>
      <FlexImg src={illustration} alt="" />
    </XPFlex>
  </Padding>
);
