import { Anchored, Button, LinkButton, Spacer, Text } from "@vericus/cadmus-ui";

import UnauthorisedImg from "@/assets/illustrations/unauthorised.png";

import { Actions, Grid, GridContent, GridImg } from "./styles";

export function UnauthorizedError() {
  return (
    <Grid>
      <GridImg
        src={UnauthorisedImg}
        alt="An illustration of a woman reading a map"
      />
      <GridContent>
        <Text kind="displayTwo">
          {"You're"} not logged into this Cadmus Assessment
        </Text>
        <Text kind="lead">
          Go to your Learning Management System (Blackboard, Canvas, Moodle or
          D2L) and click the Cadmus Assessment link. If that {"doesn't"} work,
          you may be using an unsupported browser. Try switching to{" "}
          <Anchored
            newTab
            href="https://www.google.com/chrome/browser/desktop/index.html"
          >
            <LinkButton inline>Chrome</LinkButton>
          </Anchored>
        </Text>

        <Spacer spacing={27} />
        <Actions>
          <Anchored newTab href="https://support.cadmus.io/kb-tickets/new">
            <Button kind="dark">Contact us for help</Button>
          </Anchored>
        </Actions>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}
