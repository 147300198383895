import {
  BookMarkIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  IndeterminateCircleIcon,
} from "@vericus/cadmus-icons";
import { vars } from "@vericus/cadmus-ui";

import clsx from "clsx";

import { useAppSelector } from "@/data/hooks";
import { selectExamBacktrackDisabled } from "@/features/assignment";

import * as styles from "./status-tag.css";

interface Props {
  bookmarked?: boolean;
  answered?: boolean;
  skipped?: boolean;
  expanded?: boolean;
}

/**
 * Answer status tag Icon + expanded info, displayed on the answer outline
 * sidebar.
 */
export function StatusTag(props: Props) {
  const {
    bookmarked = false,
    answered = false,
    skipped = false,
    expanded = false,
  } = props;

  if (bookmarked) {
    return <BookmarkedStatus expanded={expanded} />;
  } else if (answered) {
    return <AnsweredStatus />;
  } else if (skipped) {
    return <SkippedStatus expanded={expanded} />;
  }

  return null;
}

interface StatusProps {
  expanded: boolean;
}

const SkippedStatus = (props: StatusProps) => {
  const examBacktrackDisabled = useAppSelector(selectExamBacktrackDisabled);

  return props.expanded ? (
    <span className={clsx(styles.expanded, styles.skipped)}>
      <span style={{ marginBottom: 1 }}>skipped</span>
      {examBacktrackDisabled ? (
        <IndeterminateCircleIcon label={"skipped"} size={18} />
      ) : (
        <ExclamationCircleIcon label="" size={16} />
      )}
    </span>
  ) : (
    <span className={styles.skipIcon}>
      {examBacktrackDisabled ? (
        <IndeterminateCircleIcon label={"skipped"} size={18} />
      ) : (
        <ExclamationCircleIcon label="skipped" size={18} />
      )}
    </span>
  );
};

const BookmarkedStatus = (props: StatusProps) =>
  props.expanded ? (
    <span className={clsx(styles.expanded, styles.bookmarked)}>
      <span style={{ marginBottom: 1 }}>saved for later</span>
      <BookMarkIcon style={{ fill: vars.colors.navy400 }} label="" size={16} />
    </span>
  ) : (
    <span className={styles.statusIcon}>
      <BookMarkIcon
        style={{ fill: vars.colors.navy400 }}
        label="bookmarked"
        size={16}
      />
    </span>
  );

const AnsweredStatus = () => (
  <span className={styles.statusIcon}>
    <CheckCircleIcon label="answered" size={16} />
  </span>
);
