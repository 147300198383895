import { useMemo } from "react";

import {
  QuestionPreviewData,
  QuestionPreviewNext,
} from "@vericus/cadmus-common";
import { Callout } from "@vericus/cadmus-ui";

import { AnswerBlockFragment } from "@/generated/graphql";
import { isSnapshotEmpty, parseBlockSnapshot } from "@/stores/snapshot";

import {
  mapFieldInteraction,
  mapQuestionType,
  mapSnapshotToAnswer,
} from "../../utils/map-graphql-to-json-schema-types";
import * as styles from "./answer-preview.css";

interface Props {
  answerBlock: AnswerBlockFragment;
  /** Whether showing alert for unanswered question */
  showAlertOnUnAnswered?: boolean;
  /** Whether answer should be shuffled */
  shuffle?: boolean;
}

/**
 * Single row in the Answer Preview page.
 */
export function AnswerPreviewItem(props: Props) {
  const { answerBlock, showAlertOnUnAnswered = false, shuffle } = props;

  const [snapshot, isEmpty] = useMemo(() => {
    const blockSnapshot = answerBlock.latestBlockSnapshot;
    const snapshot = blockSnapshot && parseBlockSnapshot(blockSnapshot);
    const questionType = answerBlock.question?.questionType;
    const isEmpty = questionType
      ? isSnapshotEmpty(questionType, snapshot ?? undefined)
      : null;
    return [snapshot, isEmpty];
  }, [answerBlock]);

  const questionData: QuestionPreviewData | null = useMemo(() => {
    if (answerBlock.question) {
      return {
        question_type: mapQuestionType(answerBlock.question.questionType),
        max_score: answerBlock.points ?? null,
        answer: mapSnapshotToAnswer(
          snapshot,
          answerBlock.question.questionType
        ),
        fields: answerBlock.question.body?.fields
          ? answerBlock.question.body.fields.flatMap((field) => {
              return {
                identifier: field.identifier,
                response: defaultResponse(),
                interaction: mapFieldInteraction(
                  answerBlock.question?.questionType,
                  field.interaction,
                  shuffle,
                  answerBlock.id
                ),
              };
            })
          : null,
        question_outcome: null,
        field_outcomes: [],
      };
    }
    return null;
  }, [answerBlock, snapshot, shuffle]);

  return (
    <div className={styles.answer}>
      <QuestionPreviewNext
        mode="answer"
        questionId={answerBlock.id}
        questionTitle={`Question ${answerBlock.orderLabel}`}
        promptDoc={answerBlock.question?.body?.promptDoc ?? null}
        bookmarked={answerBlock.bookmark ?? undefined}
        data={questionData}
      />
      {showAlertOnUnAnswered && isEmpty === true && (
        <div className={styles.section}>
          <Callout>You have not answered this question</Callout>
        </div>
      )}
    </div>
  );
}

/**
 * Default field response since we hide response object from students
 */
function defaultResponse() {
  return {
    partial_scoring: null,
    match_similarity: null,
    error_margin: null,
    correct_values: [],
    correct_ranges: [],
    case_sensitive: null,
    cardinality: "single" as const,
    base_type: "string" as const,
  };
}
