import { styled } from "styled-components";

import { Button, DesktopAndTablet, Divider, Text } from "@vericus/cadmus-ui";

import feedbackIllustration from "@/assets/illustrations/student-pointing-submission-paper.png";
import { ResultFragment } from "@/generated/graphql";

import { useHeraResultLaunch } from "../../hooks/use-hera-result-launch";
import {
  ActionContainer,
  ContentContainer,
  Illustration,
  InfoContainer,
} from "./styles";

interface ExamFeedbackConfirmationProps {
  assessmentName: string;
  userName: string;
  latestResult: ResultFragment;
  gradingService?: string;
}

/**
 * ExamFeedbackConfirmation displays the feedback and grades post-exam.
 */
export const ExamFeedbackConfirmation = ({
  assessmentName,
  gradingService,
  latestResult,
  userName,
}: ExamFeedbackConfirmationProps) => {
  const onViewFeedback = useHeraResultLaunch(latestResult, gradingService);
  const { grade } = latestResult;

  const gradeInfo = grade ? grade.display : "—";
  const gradeSuffix = grade?.max !== undefined ? ` / ${grade.max}` : "";

  return (
    <Container>
      <Text kind="displayTwo">Your feedback is available now</Text>
      <Divider />
      <ContentContainer>
        <InfoContainer>
          <div>
            <Text kind="headingThree">{assessmentName}</Text>
            <Text kind="label">Submitted by {userName}</Text>
          </div>
          <ActionContainer>
            <div>
              <Text kind="label" textAlign="center">
                Grade
              </Text>
              <Text kind="headingFour" textAlign="center">
                {gradeInfo}
                {gradeSuffix}
              </Text>
            </div>

            <Button
              kind="dark"
              fullWidth
              disabled={!onViewFeedback}
              title={
                !onViewFeedback
                  ? "Feedback is not available right now"
                  : undefined
              }
              onClick={onViewFeedback}
            >
              View feedback
            </Button>
          </ActionContainer>
        </InfoContainer>
        <DesktopAndTablet>
          <Illustration src={feedbackIllustration} alt="" />
        </DesktopAndTablet>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
