import { isHangingIndent, referencesTitle } from "@vericus/cadmus-common";
import {
  CadmusEditorOptions,
  Content,
  CoreEditor,
  createCadmusEditor,
  Editor,
  getVersion,
  Sendable,
  sendableSteps,
} from "@vericus/cadmus-editor-prosemirror";

import { trackEditorEvent } from "@/client/events";
import {
  __GLOBAL_ASSESSMENT_ID,
  __GLOBAL_CLIENT_ID,
  __GLOBAL_STUDENT_ID,
  __GLOBAL_TENANT,
  __GLOBAL_WORK_ID,
} from "@/client/globals";
import GLOBAL_SUGGESTION_CLIENT from "@/client/suggestion";
import { API_ENDPOINT } from "@/config";
import { AppDispatch } from "@/data/store";
import { setPendingSnapshot } from "@/features/authority";

import { Snapshot } from "../snapshot";
import { setActiveEditor } from "./active-editor";
import defaultNotesExam from "./default-notes-exam.json";
import defaultNotes from "./default-notes.json";
import { ClassicEditorName } from "./types";

interface NewAnswerEditorArgs {
  answerBlockId: string;
  editorName: ClassicEditorName | null;
  content: Content;
  version: number;
  dispatch: AppDispatch;
  editorOpts?: Partial<CadmusEditorOptions>;
}

/** Create a new Editor from a snapshot or empty state. */
export function newAnswerEditor(args: NewAnswerEditorArgs): Editor {
  const { answerBlockId, version, editorName, content, dispatch, editorOpts } =
    args;

  const onSave = (editor: CoreEditor) => {
    const sendable = sendableSteps(editor.state);
    if (sendable) {
      selfConfirmSteps(editor, sendable);
      const snapshot = {
        version: getVersion(editor.state),
        answerDoc: editor.getJSON(),
      };
      const payload = { answerBlockId, snapshot, updateLocalSnapshot: true };
      // And queue it for saving
      dispatch(setPendingSnapshot(payload));
    }
  };

  const editor = createCadmusEditor({
    editorId: editorName ?? answerBlockId,
    editorA11yLabel: "Student answer",
    content,
    version,
    clientId: __GLOBAL_CLIENT_ID.current ?? undefined,
    suggestionClient: GLOBAL_SUGGESTION_CLIENT,
    imageAPIBase: `${API_ENDPOINT}/api/work_media`,
    tenant: __GLOBAL_TENANT.current ?? undefined,
    assessmentId: __GLOBAL_ASSESSMENT_ID.current ?? undefined,
    workId: __GLOBAL_WORK_ID.current ?? undefined,
    userId: __GLOBAL_STUDENT_ID.current ?? undefined,
    userRole: "student",
    contentPlaceholder: "Write your answer here…",
    autofocus: true,
    enablePasteAnnotations: true,
    onEditorEvent: trackEditorEvent,
    onSave: (_editorId, editor) => {
      onSave(editor);
    },
    cursorBottomPadding: 144,
    ...editorOpts,
  });

  editor.on("focus", () => {
    setActiveEditor(answerBlockId, editor, editorName);
  });

  return editor;
}

// Auto confirm steps
function selfConfirmSteps(editor: CoreEditor, sendable: Sendable) {
  const clientIds = sendable.steps.map(() => sendable.clientID);
  editor.commands.receiveSteps(sendable.steps, clientIds);
}

/** Instruction Sheet information providing additional setup for the editors. */
export type SheetProps = {
  isExam: boolean;
  referencingStyle: string | null;
  isMultiformat: boolean;
  isLockDownExam: boolean;
  assessmentName: string;
};

/**
 * Create a Editor from snapshots of classic editor.
 */
export function newClassicEditor(
  editorName: ClassicEditorName,
  answerBlockId: string,
  snapshot: Snapshot | null,
  dispatch: AppDispatch,
  sheetProps: SheetProps
): Editor {
  return newAnswerEditor({
    editorName,
    answerBlockId,
    content:
      snapshot?.answerDoc ??
      getDefaultContent(
        editorName,
        sheetProps.assessmentName,
        sheetProps.isExam,
        sheetProps.referencingStyle
      ),
    version: snapshot?.version ?? 0,
    dispatch,
    editorOpts: buildClassicEditorOpts(editorName, sheetProps),
  });
}

function buildClassicEditorOpts(
  editorName: ClassicEditorName,
  sheetProps: SheetProps
): Partial<CadmusEditorOptions> {
  const { isExam, isLockDownExam, referencingStyle } = sheetProps;

  const enableHangingIndent = isHangingIndent(referencingStyle);

  const commonOpts: Partial<CadmusEditorOptions> = {
    onEditorEvent: trackEditorEvent,
    enableManual: !isExam,
    enableHyperlink: !isLockDownExam,
    enableImage: !isLockDownExam,
  };

  switch (editorName) {
    case ClassicEditorName.Body:
      return {
        ...commonOpts,
        editorA11yLabel: "Assessment body editor",
        enablePasteAnnotations: true,
        titlePlaceholder: "Give your work a title",
        contentPlaceholder: "Write your submission here...",
        autofocus: false,
        minBlocks: 2,
        enableFootnoting: true,
      };
    case ClassicEditorName.Notes:
      return {
        ...commonOpts,
        editorA11yLabel: "Assessment notes editor",
        titlePlaceholder: "Notes...",
        contentPlaceholder:
          "Use this space to plan, take notes, or work through your checklist. Your notes won’t be submitted with your work.",
        autofocus: false,
      };
    case ClassicEditorName.References:
      return {
        ...commonOpts,
        editorA11yLabel: "Assessment references editor",
        titlePlaceholder: "References...",
        referencesPlaceholder: true,
        enableHangingIndent: enableHangingIndent ?? false,
        autofocus: false,
      };

    default:
      return commonOpts;
  }
}

function getDefaultContent(
  editorName: ClassicEditorName,
  assessmentName: string,
  isExam: boolean,
  referencingStyle: string | null
): Content {
  const defaultBodyContent: Content = `<h1>${assessmentName}</h1>`;
  const defaultNotesContent: Content = isExam ? defaultNotesExam : defaultNotes;
  const defaultReferencesContent: Content = `<h1>${referencesTitle(referencingStyle)}</h1><p></p>`;

  switch (editorName) {
    case ClassicEditorName.Body:
      return defaultBodyContent;
    case ClassicEditorName.Notes:
      return defaultNotesContent;
    case ClassicEditorName.References:
      return defaultReferencesContent;
  }
}
