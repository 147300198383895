import { EditorEvent } from "@vericus/cadmus-editor-prosemirror";
import { ManualEvent } from "@vericus/cadmus-manual";

import { __GLOBAL_SESSION_ID } from "client/globals";
import { ResourceFragment } from "generated/graphql";
import { time } from "utils/serverTime";

import { JitsuEvent } from "@/data/events/JitsuEvent";

import jitsuClient from "./jitsu";

// Event properties for Editor Pasted event
interface EditorPastedProps {
  editor_id: string;
  paste_internal: boolean | null;
  paste_type: string | null;
  word_count: number | null;
  words_added: number | null;
  words_deleted: number | null;
  paste_origin: string | null;
  s3_payload: string | null;
}

// Event properties for Editor History Changed event
interface EditorHistoryProps {
  editor_id: string;
  history_type: "undo" | "redo";
  words_added: number | null;
  words_deleted: number | null;
}

// Event properties for Resource Viewed event
interface ResourceViewedProps {
  resource_id: string;
  resource_url: string | null;
  resource_name: string | null;
}

// Event properties for Manual Viewed event
interface ManualViewedProps {
  manual_page: string;
  manual_location: string | null;
}

/**
 * Track editor events as their eventlogs event counterparts.
 *
 * Events tracked:
 *   - `Editor Pasted`
 *   - `Editor History Changed`
 */
export function trackEditorEvent(editorEvent: EditorEvent) {
  switch (editorEvent.type) {
    case "paste": {
      const payload = editorEvent.payload;
      const props: EditorPastedProps = {
        editor_id: payload.editorId,
        s3_payload: JSON.stringify(payload),
        words_added: payload.addedWords,
        words_deleted: payload.deletedWords,
        word_count: payload.wordCount ?? null,
        paste_type: payload.pasteType ?? null,
        paste_internal: payload.internal ?? null,
        paste_origin: payload.origin ?? null,
      };
      track(JitsuEvent.EDITOR_PASTED, props);
      break;
    }

    case "history": {
      const payload = editorEvent.payload;
      const props: EditorHistoryProps = {
        words_added: payload.addedWords,
        words_deleted: payload.deletedWords,
        history_type: payload.historyType,
        editor_id: payload.editorId,
      };
      track(JitsuEvent.EDITOR_HISTORY_CHANGED, props);
      break;
    }
  }
}

/**
 * Track a "Resource Viewed" event.
 */
export function trackResourceViewedEvent(resource: ResourceFragment) {
  const props: ResourceViewedProps = {
    resource_id: resource.id,
    resource_url: resource.url,
    resource_name: resource.name,
  };
  track(JitsuEvent.RESOURCE_VIEWED, props);
}

/**
 * Track a ManualEvent as the eventlogs "Manual Viewed" event.
 */
export function trackManualEvent(event: ManualEvent) {
  const props: ManualViewedProps = {
    manual_page: event.page,
    manual_location: event.location ?? null,
  };
  track(JitsuEvent.MANUAL_VIEWED, props);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function track(eventType: string, properties: Record<string, any>) {
  const timestamp = new Date(time()).toISOString();
  jitsuClient.track(eventType, {
    ...properties,
    timestamp,
  });
}
