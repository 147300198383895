import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { hydrateSnapshots } from "@/features/authority";
import { QuestionType } from "@/generated/graphql";
import { readAnswerBlocks } from "@/graphql/selectors";
import { getItem, intParser } from "@/utils/localStorage";

export interface AnswerSliceState {
  /** Ordered list of all answer IDs in the assessment. */
  pages: PageInfo[];
  /** Current page index of the `pages` array. */
  currentPageIndex: number;
}

/** Different kinds of pages in Multi-Format */
export type PageInfo =
  | {
      page: "answer";
      answerId: string;
      questionType?: QuestionType;
      parentAnswerId?: string;
    }
  | { page: "agreement" }
  | { page: "overview"; blockId?: string };

const initialState: AnswerSliceState = {
  currentPageIndex: 0,
  pages: [],
};

/**
 * Load the initial page index from localStorage to provide a resume behaviour.
 * The `prefix` is prepended to the localStorage key to namespace it.
 *
 * ## Payload
 *
 * Either one of these will be present in the payload:
 *
 *    * `pageIndex` - (number | null) - The page index to switch to.
 *    * `answerId` - (string | null) - The answer ID to switch to.
 */
export const loadInitialPage = createAction(
  "answer/loadInitialPage",
  (prefix: string, examBacktrackDisabled: boolean | null) => {
    let pageIndex: number | null = null;
    let answerId: string | null = null;

    pageIndex = getItem(prefix, "currentPageIndex", intParser);

    if (examBacktrackDisabled) {
      // Don't use the localStorage value if examBacktrackDisabled
      pageIndex = null;

      // Find the first non-overview block that is NOT confirmed to switch to
      // that block directly
      const answerBlocks = readAnswerBlocks();
      const firstUnconfirmedAnswer = examBacktrackDisabled
        ? answerBlocks.find((answerBlock) => answerBlock.confirm !== true)
        : undefined;

      if (
        firstUnconfirmedAnswer?.question?.questionType === QuestionType.Overview
      ) {
        pageIndex = 0;
      } else {
        answerId = firstUnconfirmedAnswer?.id ?? null;
      }
    }

    return {
      payload: {
        pageIndex,
        answerId,
      },
    };
  }
);

export const answerSlice = createSlice({
  name: "answer",
  initialState: initialState,
  reducers: {
    /** Go to the previous page in the loaded page list. */
    goPrevPage: (state) => {
      const currIndex = state.currentPageIndex;
      const prevIndex = currIndex - 1;
      if (prevIndex >= 0) {
        state.currentPageIndex = prevIndex;
      }
    },
    /** Go to the next page in the loaded page list. */
    goNextPage: (state) => {
      const currIndex = state.currentPageIndex;
      const nextIndex = currIndex + 1;
      if (nextIndex < state.pages.length) {
        state.currentPageIndex = nextIndex;
      }
    },
    /** Go to a new page. */
    goToPage: (state, action: PayloadAction<PageInfo>) => {
      const index = state.pages.findIndex((pg) => {
        if (action.payload.page === "answer") {
          return (
            pg.page === "answer" && pg.answerId === action.payload.answerId
          );
        }
        return pg.page === action.payload.page;
      });
      if (index >= 0) {
        state.currentPageIndex = index;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadInitialPage, (state, action) => {
      const { answerId, pageIndex } = action.payload;
      if (answerId) {
        const index = state.pages.findIndex((page) => {
          return page.page === "answer" && page.answerId === answerId;
        });
        if (index !== -1) {
          state.currentPageIndex = index;
        }
      } else if (pageIndex !== null) {
        state.currentPageIndex = pageIndex;
      }
    });

    // Setup the answer pages navigation
    builder.addCase(hydrateSnapshots, (state, action) => {
      const { answers } = action.payload;
      // Cache of sub question id (parent question ids)
      const parentAnswers: { nodeId: string | null; answerId: string }[] = [];
      const answerPages: PageInfo[] = answers.flatMap((block) => {
        if (block.question?.questionType === QuestionType.Overview) {
          return [];
        }
        if (block.question?.questionType === QuestionType.Sub) {
          // This answer is a sub question, so is a parent question and cache it
          parentAnswers.push({
            nodeId: block.nodeId,
            answerId: block.id,
          });
        }

        let parentAnswerId: string | undefined = undefined;
        if (block.parentNodeId) {
          parentAnswerId = answers.find(
            (ans) => ans.nodeId === block.parentNodeId
          )?.id;
        }

        return [
          {
            page: "answer",
            answerId: block.id,
            questionType: block.question?.questionType,
            parentAnswerId,
          },
        ];
      });

      const overviewBlockId = answers.find(
        (block) => block.question?.questionType === QuestionType.Overview
      )?.id;

      state.pages = [
        { page: "overview", blockId: overviewBlockId },
        ...answerPages,
        { page: "agreement" },
      ];
    });
  },
});

export const answerReducer = answerSlice.reducer;
export const { goPrevPage, goNextPage, goToPage } = answerSlice.actions;
