import { useState } from "react";
import { styled } from "styled-components";

import { Button, Spacer, Text } from "@vericus/cadmus-ui";

import { useNavigate } from "react-router-dom";
import { Grid } from "styles/layout";

import UnauthorisedImg from "@/assets/illustrations/unauthorised.png";
import { API_ENDPOINT } from "@/config";

const LOGOUT_ENDPOINT = `${API_ENDPOINT}/auth/logout`;

/** Logout confirmation page with a chance to Cancel and go back */
export function Logout() {
  const [working, setWorking] = useState(false);

  const navigate = useNavigate();

  // Ask pantheon to drop the session
  const onLogout = () => {
    setWorking(true);
    fetch(LOGOUT_ENDPOINT, { method: "POST", credentials: "include" }).then(
      () => {
        navigate("/goodbye");
      }
    );
  };

  return (
    <Grid>
      <GridImg
        src={UnauthorisedImg}
        alt="An illustration of a woman reading a map"
      />

      <GridContent>
        <Text kind="displayTwo">Log out of Cadmus?</Text>
        <Text kind="lead">
          Your login information will be cleared from this browser. You can log
          back into your Cadmus Assessment through your University LMS.
        </Text>
        <Spacer spacing={27} />
        <Button
          onClick={() => {
            navigate(-1);
          }}
          disabled={working}
        >
          Cancel
        </Button>
        <Button
          kind="dark"
          marginLeft={18}
          onClick={onLogout}
          disabled={working}
        >
          Log out
        </Button>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}

const GridImg = styled.img`
  grid-column: 3 / span 5;
  width: 100%;
  margin-top: 72px;

  @media screen and (max-width: 900px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 630px) {
    grid-column: 1 / span 9;
  }
`;

const GridContent = styled.div`
  grid-column: 3 / span 5;
  text-align: center;
  margin-top: 18px;

  @media screen and (max-width: 900px) {
    grid-column: 1 / span 9;
  }
`;
