import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { __GLOBAL_SESSION_ID } from "@/client/globals";
import { onOffline, onOnline } from "@/data/setup-dom-listeners";
import {
  AnswerBlockFragment,
  SaveFragment,
  SubmissionType,
} from "@/generated/graphql";
import {
  parseBlockSnapshot,
  parseSaveSnapshots,
  Snapshot,
} from "@/stores/snapshot";

import { saveBlockSnapshot, saveFull, submit } from "./actions";
import {
  AuthorityState,
  ConnectionStatus,
  PendingSnapshot,
  SavingMechanism,
} from "./types";

const initialState: AuthorityState = {
  sessionLock: false,
  hasDraft: false,
  hasFinal: false,
  savingMechanism: SavingMechanism.FULL,
  saveError: null,
  saveAttempt: 0,
  savesInFlight: [],
  connectionStatus: ConnectionStatus.CONNECTED,
  hasAcceptedSubmissionDeclaration: true,
  submitPreview: false,
  submitPromptOpened: null,
  submitLoading: false,
  submitError: null,
  snapshots: {},
  pendingSnapshots: [],
};

// Payload for hydrateWork action to syncrhonise the Apollo cache and Redux
interface HydrateWorkPayload {
  assessmentId: string;
  workId: string;
  hasDraft: boolean;
  hasFinal: boolean;
}

// Payload describing a Snapshot and the Answer Block ID that it belongs to
interface SnapshotPayload {
  answerBlockId: string;
  snapshot: Snapshot;
}

export const authoritySlice = createSlice({
  name: "authority",
  initialState: initialState,
  reducers: {
    hydrateWork: (state, action: PayloadAction<HydrateWorkPayload>) => {
      state.workId = action.payload.workId;
      state.hasDraft = action.payload.hasDraft;
      state.hasFinal = action.payload.hasFinal;
    },
    clearSaveErrors: (state) => {
      state.saveError = null;
    },
    /** Lock the current work session. */
    lockWorkSession: (state) => {
      state.sessionLock = true;
    },
    /** Unlock the current work session. */
    verifyWorkSession: (state) => {
      state.sessionLock = false;
    },
    /** Accept the academic integrity submission declaration. */
    acceptSubmissionDeclaration: (state) => {
      state.hasAcceptedSubmissionDeclaration = true;
    },
    /** Decline the academic integrity submission declaration. */
    declineSubmissionDeclaration: (state) => {
      state.hasAcceptedSubmissionDeclaration = false;
    },
    /**
     * Open the timed submit prompt which lets you submit on time or continue
     * submitting late.
     *
     * The Payload is an ISO8601 Date string.
     */
    openSubmitPrompt: (state, action: PayloadAction<string>) => {
      state.submitPromptOpened = action.payload;
    },
    /**
     * Close the timed submit prompt.
     */
    closeSubmitPrompt: (state) => {
      state.submitPromptOpened = null;
    },
    /** Clear all kinds of error states. */
    clearError: (state) => {
      state.submitError = null;
      state.submitLoading = false;
    },
    /** Trigger the submission preview state. */
    openPreview: (state) => {
      state.submitPreview = true;
    },
    /** Hide the submission preview state. */
    hidePreview: (state) => {
      state.submitPreview = false;
    },
    /** Update the current saving mechanism. */
    updateSavingMechanism(state, action: PayloadAction<SavingMechanism>) {
      state.savingMechanism = action.payload;
    },

    /** Hydrate local state of latest answer snapshots from GraphQL data.*/
    hydrateSnapshots: (
      state,
      action: PayloadAction<{
        answers: AnswerBlockFragment[];
        save?: SaveFragment | null;
        assessmentName: string;
      }>
    ) => {
      // Collect and parse latest Snapshots from AnswerBlocks
      const answerBlockSnapshots: Record<string, Snapshot> = {};
      action.payload.answers.forEach((answer) => {
        if (answer.latestBlockSnapshot) {
          const snapshot = parseBlockSnapshot(answer.latestBlockSnapshot);
          answerBlockSnapshots[answer.id] = snapshot;
        }
      });
      // Map a classic Save into Snapshots, identified by EditorId instead of
      // AnswerBlock ID
      const classicEditorSnapshots = action.payload.save
        ? parseSaveSnapshots(action.payload.save)
        : {};

      // All the snapshots
      state.snapshots = {
        ...answerBlockSnapshots,
        ...classicEditorSnapshots,
      };
    },

    /**
     * Update the local snapshot for an AnswerBlock.
     *
     * The snapshot won't immediately persist to the server, but will have a
     * listener that debounces on this action and then adds it to the pending
     * snapshot queue.
     */
    setLocalSnapshot: (state, action: PayloadAction<SnapshotPayload>) => {
      state.snapshots[action.payload.answerBlockId] = action.payload.snapshot;
    },

    /** Add a snapshot to the snapshot queue to save. */
    setPendingSnapshot: (
      state,
      action: PayloadAction<SnapshotPayload & { updateLocalSnapshot?: boolean }>
    ) => {
      const { answerBlockId, snapshot } = action.payload;
      if (action.payload.updateLocalSnapshot) {
        state.snapshots[answerBlockId] = snapshot;
      }
      state.pendingSnapshots = addPendingSnapshot(
        state.pendingSnapshots,
        answerBlockId,
        snapshot
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveBlockSnapshot.pending, (state, action) => {
      state.savesInFlight = addUnique(
        state.savesInFlight,
        action.meta.requestId
      );
      state.saveAttempt += 1;
    });

    builder.addCase(saveBlockSnapshot.fulfilled, (state, action) => {
      state.savesInFlight = removeUnique(
        state.savesInFlight,
        action.meta.requestId
      );
      state.saveError = null;
      state.connectionStatus = ConnectionStatus.CONNECTED;
      state.saveAttempt = 0;

      state.pendingSnapshots = ackPendingSnapshot(
        state.pendingSnapshots,
        action.meta.arg.answerBlockId,
        action.meta.arg.snapshot.version
      );
    });

    builder.addCase(saveBlockSnapshot.rejected, (state, action) => {
      state.savesInFlight = removeUnique(
        state.savesInFlight,
        action.meta.requestId
      );
      // If the rejection payload is our `Save Error` - use it.
      // All other forms of Error (the default `SerializedError`) is considered a network error.
      if (action.payload) {
        state.saveError = action.payload;
      } else {
        state.saveError = {
          kind: "network",
          title: action.error?.name ?? "Network disruption",
          detail: action.error?.message,
        };
      }

      // For now in case the version was "already_reported" - just remove it from pending queue
      if (action.payload?.kind === "already_reported") {
        state.pendingSnapshots = ackPendingSnapshot(
          state.pendingSnapshots,
          action.meta.arg.answerBlockId,
          action.meta.arg.snapshot.version
        );
      }
    });

    builder.addCase(saveFull.pending, (state, action) => {
      state.savesInFlight = addUnique(
        state.savesInFlight,
        action.meta.requestId
      );
      state.saveAttempt += 1;
    });

    builder.addCase(saveFull.fulfilled, (state, action) => {
      state.savesInFlight = removeUnique(
        state.savesInFlight,
        action.meta.requestId
      );
      state.saveError = null;
      state.connectionStatus = ConnectionStatus.CONNECTED;
      state.saveAttempt = 0;

      // flush the pending snapshots that were saved
      if (action.meta.arg?.pendingSnapshot) {
        const { answerBlockId, snapshot } = action.meta.arg.pendingSnapshot;
        state.pendingSnapshots = ackPendingSnapshot(
          state.pendingSnapshots,
          answerBlockId,
          snapshot.version
        );
      }
    });

    builder.addCase(saveFull.rejected, (state, action) => {
      state.savesInFlight = removeUnique(
        state.savesInFlight,
        action.meta.requestId
      );
      if (action.payload) {
        state.saveError = action.payload;
      } else {
        state.saveError = {
          kind: "network",
          title: action.error?.name ?? "Network disruption",
          detail: action.error?.message,
        };
      }
    });

    builder.addCase(submit.pending, (state) => {
      state.submitLoading = true;
    });

    builder.addCase(submit.rejected, (state, action) => {
      state.submitLoading = false;
      state.submitPromptOpened = null;

      if (action.payload) {
        // If the rejection payload is our `SubmitError` use it,
        state.submitError = action.payload;
      } else {
        // All other forms of Error (the default `SerializedError`) is
        // considered a network error.
        state.submitError = {
          kind: "network",
          title: action.error.name ?? "Network disruption",
          detail: action.error.message,
        };
      }
    });

    builder.addCase(submit.fulfilled, (state, action) => {
      state.submitLoading = false;
      state.submitPreview = false;
      state.submitError = null;
      state.submitPromptOpened = null;

      if (action.meta.arg.submissionType === SubmissionType.Draft) {
        state.hasDraft = true;
      }
      if (action.meta.arg.submissionType === SubmissionType.Final) {
        state.hasFinal = true;
      }
    });

    builder.addCase(onOffline, (state, _action) => {
      state.connectionStatus = ConnectionStatus.DISCONNECTED;
    });

    builder.addCase(onOnline, (state, _action) => {
      state.connectionStatus = ConnectionStatus.CONNECTED;
    });
  },
});

// Remove a unique item from a unique list.
function removeUnique<I>(items: I[], item: I): I[] {
  return items.filter((i) => i !== item);
}

// Add a unique item to a unique list.
function addUnique<I>(items: I[], item: I): I[] {
  if (items.includes(item)) {
    return items;
  }
  return [item, ...items];
}

// Add a new PendingSnapshot to an existing unique list or update the entry for
// the Answer Block ID with the new pending item.
function addPendingSnapshot(
  queue: PendingSnapshot[],
  answerBlockId: string,
  snapshot: Snapshot
): PendingSnapshot[] {
  const newItems = queue.filter((p) => {
    if (
      p.answerBlockId === answerBlockId &&
      p.snapshot.version <= snapshot.version
    ) {
      return false;
    }
    return true;
  });

  const pending = { answerBlockId, snapshot };
  return [pending, ...newItems];
}

// Acknowledge a snapshot version is saved, thereby removing all versions below
// it from the pending queue
function ackPendingSnapshot(
  queue: PendingSnapshot[],
  answerBlockId: string,
  version: number
): PendingSnapshot[] {
  return queue.filter((p) => {
    if (p.answerBlockId === answerBlockId && p.snapshot.version <= version) {
      return false;
    }
    return true;
  });
}

export const authorityReducer = authoritySlice.reducer;

export const {
  updateSavingMechanism,
  hydrateWork,
  lockWorkSession,
  verifyWorkSession,
  clearSaveErrors,
  openPreview,
  hidePreview,
  openSubmitPrompt,
  closeSubmitPrompt,
  acceptSubmissionDeclaration,
  declineSubmissionDeclaration,
  clearError,
  hydrateSnapshots,
  setLocalSnapshot,
  setPendingSnapshot,
} = authoritySlice.actions;
